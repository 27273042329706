import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('UserProfilePage.myOrders')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/profile" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.orders, (order) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: order.id,
                          "router-link": {
              name: 'OrderDetailPage',
              params: { id: order.id },
              state: { passedOrder: JSON.stringify(order) },
            },
                          button: "",
                          detail: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, _toDisplayString($setup.t('OrderPage.orderNumber')) + "#" + _toDisplayString(order.id), 1),
                                _createElementVNode("p", null, _toDisplayString($setup.formatDate(order.createdAt)), 1),
                                _createElementVNode("p", null, _toDisplayString($setup.t('OrderPage.total')) + "HK$ " + _toDisplayString(order.totalPrice), 1),
                                _createVNode(_component_ion_badge, {
                                  color: $setup.getOrderStatusColor(order.status)
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($setup.t(order.status)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["router-link"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}