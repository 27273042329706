
// Vue reactivity
import { ref } from 'vue';

// icons
import { add } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonSpinner, IonList, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonBackButton, IonBadge } from '@ionic/vue';

// API services
import OrderService from '@/services/OrderService';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  name: 'OrderListPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonSpinner, IonList, IonItem, IonLabel, IonIcon,
                IonButtons, IonButton, IonBackButton, IonBadge, },
  setup() {
    // 1. declare state variables (ref to make them reactive)
    const loading = ref(true);
    const orders = ref<any>(null);

    // 2. fetch data from API and store data in state variables
    OrderService.getUserOrders().then(res => {
      loading.value = false;
      orders.value = res;
    });

    // methods or filters
    const { t } = useI18n();
    const { getOrderStatusColor, formatDate } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add,

      // variables
      loading, orders,

      // methods
      formatDate, getOrderStatusColor,
    }
  }
}
